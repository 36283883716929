import { Component, OnInit } from "@angular/core"

@Component({
    templateUrl: "./faq.component.html"
})
export class FAQComponent implements OnInit {
    faqs: any[] = [
        {
            pregunta: `<p>O que é Aprendo Livre?</p>`,
            respuesta: `<p>Uma iniciativa que procura apoiar todos os estudantes no seu caminho de preparação para o <b>ENEM</b>, oferecendo-lhes materiais, ferramentas e apoio tecnológico que só poderiam encontrar nas alternativas mais caras do mercado. </p>`
        },
        {
            pregunta: `<p>O que é o ENEM?</p>`,
            respuesta: `<p>O Exame Nacional do Ensino Médio <b>(ENEM) é</b> o teste que a maioria das universidades públicas e privadas no Brasil adotaram como método de admissão para os candidatos através do Sistema de Seleção Unificado (<b>Sisu</b>).</p>`
        },
        {
            pregunta: `<p>Como posso preparar-me para o ENEM 2022 de graça?</p>`,
            respuesta: `<p>Apesar da grande maioria dos cursos preparatórios para o <b>ENEM</b> serem pagos, existem alternativas como o <b>Apendo Livre</b>, uma plataforma online de preparação para testes que é <b>completamente gratuita</b> para todos os alunos que se preparam para o ENEM.</p>`
        },
        {
            pregunta: `<p>Em qual curso eu preciso estar para poder me inscrever?</p>`,
            respuesta: `<p>O acesso ao <b>Aprendo Livre</b> está aberto a todos, no entanto, recomendamos seu uso para alunos que cursam os últimos anos do <b>ensino médio</b> ou se preparando para fazer o <b>ENEM</b> novamente.</p>`
        },
        {
            pregunta: `<p>Tenho de pagar uma taxa para me juntar à Aprendo Livre? </p>`,
            respuesta: `<p>O uso do <b>Aprendo Livre</b> é <b>completamente gratuito e ilimitado, sem taxas ou contratos ocultos</b>. Oferecemos todas as nossas ferramentas e material sem limite ou distinção, o nosso apoio é para todos.</p>`
        },
        {
            pregunta: `<p>O que posso encontrar em Aprendo Livre?</p>`,
            respuesta: `<p><b>Aprendo Livre</b> oferece uma experiência completamente <b>online</b> e <b>gratuita</b>. Você poderá se preparar da melhor maneira para o ENEM com nossas muitas ferramentas: </p>
            <ul>
                <li>Testes de preparação ENEM com feedback imediato</li>
                <li>Avaliações recomendadas por disciplina com feedback imediato</li>
                <li>Currículos adaptativos</li>
                <li>Currículos recomendados</li>
                <li>Currículos intensivos (Caminho para o sucesso no ENEM)</li>
                <li>Apoio estatístico e relatórios de desempenho</li>
                <li>Material de apoio multimédia para cada disciplina ENEM</li>
                <li>Aulas de preparação ENEM ao vivo</li>
                <li>Comunidade para a colaboração e resolução de dúvidas.</li>
            </ul>
            `
        },
        {
            pregunta: `<p>Já me inscrevi. Como posso começar a preparar-me para o ENEM?</p>`,
            respuesta: `<p>Recomendamos que comece a sua preparação com os nossos <b><u>planos adaptados</u></b>, pois irão ajudá-lo a compreender os seus pontos fortes e fracos. Através da nossa IA, vamos ajudá-lo a definir um caminho de preparação feito à sua medida. Além disso, convidamo-lo a visitar a nossa secção de <b><u>Ajuda</u></b> onde lhe mostraremos como tirar o máximo partido das ferramentas que a <b>Aprendo Livre</b> tem para você.</p>`
        },
        {
            pregunta: `<p>O Aprendo Livre tem conteúdo para todos os testes ENEM?</p>`,
            respuesta: `<p><b>Aprendo Livre</b> dispõe atualmente de testes, simuladores e conteúdos de preparação multimédia para os 4 principais testes; <b>Matemática, Linguagens e Códigos, Ciências da Natureza</b> e <b>Ciências Humanas</b>. Estamos trabalhando arduamente para fornecer material de preparação focado no teste <b>da Redação</b>.</p>`
        },
        {
            pregunta: `<p>O Aprendo Livre está alinhado com INEP ?</p>`,
            respuesta: `<p>Todos os nossos conteúdos e materiais de apoio são verificados e alinhados com o Instituto Nacional de Estudos e Pesquisas Educacionais Anísio Teixeira (<b>INEP</b>)</p>`
        },
        {
            pregunta: `<p>Como posso saber se estou suficientemente preparado para o teste?</p>`,
            respuesta: `<p>Através dos nossos testes tipo ENEM, com simuladores de pontuação, planos de estudo adaptativos, recomendados e intensivos, poderá acompanhar as suas sessões de prática e, através do feedback imediato fornecido pela plataforma, poderá identificar atempadamente os seus pontos fortes e fracos em cada disciplina.</p>`
        },
        {
            pregunta: `<p>O que é um plano de estudo recomendado?</p>`,
            respuesta: `<p>Estes são planos que você pode encontrar no Aprendo Livre, compostos de várias sessões, que podem conter guias de estudo, material de apoio audiovisual e avaliações diagnósticas.</p>`
        },
        {
            pregunta: `<p>O que é um plano Adaptado?</p>`,
            respuesta: `<p>Para iniciar seu <b>plano adaptado</b>, você precisará completar uma avaliação diagnóstica para <b>identificar as áreas que você precisa reforçar de acordo com seu desempenho e o assunto que você escolheu</b>. Uma vez concluído o seu teste de diagnóstico, o <b>nosso sistema de avaliação baseado em IA</b> irá desenhar o seu plano de estudo pessoal, totalmente adaptado ao seu desempenho e necessidades.</p>
            <p>O plano pessoal irá mostrar-lhe as áreas que precisa de reforçar de acordo com os seus resultados, guiá-lo durante todo o processo de estudo através de sessões compostas por aulas em vídeo, textos de apoio e avaliações para pôr em prática o seu progresso. </p>
            `
        },
        {
            pregunta: `<p>Quanto tempo demora a preparação para o ENEM?</p>`,
            respuesta: `<p>Na plataforma você encontrará nosso calendário e nosso <b>plano de estudos</b> de preparação <b>intensiva para o ENEM</b>. Durante 8 semanas, através de vídeos, aulas ao vivo e avaliações regulares, você se preparará para todos os componentes das 4 provas. </p>`
        },
        {
            pregunta: `<p>Como posso simular/calcular a minha pontuação no ENEM?</p>`,
            respuesta: `<p>Você pode encontrar simulações ENEM por disciplina na seção <b><u>Prática</u></b> de <b>Aprendo Livre</b>. Estes <b>testes mensais irão</b> ajudá-lo a praticar para o teste e permitir-lhe-á <b>acompanhar as suas pontuações simuladas ao longo do</b> tempo. </p>`
        },
        {
            pregunta: `<p>O Aprendo Livre tem aulas ao vivo para o ENEM?</p>`,
            respuesta: `<p>Aprendo Livre tem aulas ao vivo completamente gratuitas para todas as provas <b>ENEM</b>. Você pode consultar nosso calendário em nossas redes sociais e na plataforma.</p>`
        },
        {
            pregunta: `<p>Qual é o horário das aulas ao vivo?</p>`,
            respuesta: `<p>Os horários das aulas ao vivo podem ser encontrados na <b><u>Seção de Estudos</u></b> e em nossa conta oficial Instagram <b>@AprendoLivre</b>.</p>`
        },
        {
            pregunta: `<p>Onde posso encontrar os testes ENEM anteriores?</p>`,
            respuesta: `<p>Aprendo Livre tem uma série de <b>testes recomendados</b> para ajudá-lo durante a sua preparação, e você também pode encontrar edições anteriores do ENEM para melhorar seus estudos.</p>`
        },
        {
            pregunta: `<p>Quais são as melhores carreiras para se estudar no Brasil?</p>`,
            respuesta: `<p>Você pode consultar todas as informações relativas à seleção de carreiras e universidades na seção <b>Aprendo Livre</b> <b><u>Orientação</u></b>.</p>`
        },
        {
            pregunta: `<p>Onde posso encontrar currículos gratuitos para o ENEM?</p>`,
            respuesta: `<p>Na <b>Aprendo Livre</b> você pode escolher os currículos desenhados para cada disciplina. Os currículos recomendados estão divididos em classes e áreas de conteúdo.</p>`
        },
        {
            pregunta: `<p>Qual é o melhor curso pré-vestibular gratuito?</p>`,
            respuesta: `<p>Existem numerosas alternativas e cursos de preparação para o ENEM. <b>Aprendo Livre</b> oferece uma <b>experiência completamente personalizada, online, gratuita e ilimitada</b>. Através das suas funcionalidades, apoia os alunos na prática e familiarização com o formato dos testes que compõem o <b>ENEM</b>. Além de ferramentas automatizadas, planos de estudo adaptativos, feedback imediato e suporte estatístico, <b>Aprendo Livre apresenta aulas de preparação ENEM totalmente ao vivo</b>.</p>`
        },
        {
            pregunta: `<p>O que é a RRSS de Aprendo Livre?</p>`,
            respuesta: `<p>Instagram: <a href="https://www.instagram.com/aprendolivre/">@AprendoLivre</a><br/>YouTube: <a href="https://www.youtube.com/c/AprendoLivre">@AprendoLivre</a></p>`
        }
    ]

    constructor() {}

    ngOnInit() {}
}
