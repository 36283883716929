import { PuntajeUniversidadModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { UtilModule, TranslationsModule } from "@puntaje/shared/core"

import { UniversidadModule } from "@puntaje/puntaje/new-modules/universidad"
import { universidadRoutes } from "./alumnos-universidad.routing"

@NgModule({
    declarations: [],
    imports: [
        UtilModule,
        TranslationsModule,
        CommonModule,
        AlumnosLayoutsModule,
        universidadRoutes,
        UniversidadModule,
        PuntajeUniversidadModule
    ]
})
export class AlumnosUniversidadModule {}
