import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    CarrerasConf,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    generar = {
        generar: false,
        intrumentosRecomendados: false,
        pruebasPropias: true,
        buscador: true
    }

    registro = {
        enableCarreras: null,
        universidades: [],
        amount: 3,
        enableNivel: null,
        enableColegio: null,
        enableTelefono: null,
        enableFechaNacimiento: null,
        nombresObligatorio: null,
        apellidoPaternoObligatorio: null,
        apellidoMaternoObligatorio: null,
        carrerasObligatorio: null,
        telefonoObligatorio: null,
        nivelObligatorio: null,
        colegioObligatorio: null,
        fechaNacimientoObligatorio: null
    }
    carreras = {
        ponderables: []
    } as CarrerasConf
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.svg",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.svg",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.svg",
            logoSquareLight: "assets/img/logos/logoSquareLight.svg",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            navbar: {
                logo: null,
                logoMobile: null
            },
            footer: {
                logo: null
            },
            landing: {
                backgroundImage: null,
                logo: null,
                subtitle: null,
                subtitleHasShadow: null,
                logoFooter: null
            },
            sharedLayout: {
                backgroundImage:
                    "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/aprendolivre/shared/background.png",
                logo: null
            }
        },
        navigationByApp: {
            alumnos: [
                {
                    icon: "libro2-o",
                    name: "Blog",
                    route: "/blog"
                },
                {
                    icon: "editar-prueba",
                    name: "Ejercita",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "prueba",
                    name: "Practica",
                    route: "/evaluaciones/recomendadas"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/pruebas/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes adaptativos",
                    route: "/planes_personales"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase",
                    route: "/plan_clases"
                },
                {
                    icon: "dialogos",
                    name: "Dudas del curso",
                    route: "/dudas"
                },
                {
                    icon: "dialogos",
                    name: "Comunidad",
                    route: "/dudas_categorias"
                },
                {
                    icon: "dialogos",
                    name: "Centro de ayuda",
                    route: "/ayudas"
                },
                {
                    icon: "establecimiento-o",
                    name: "Carreras",
                    route: "/mis_carreras"
                }
            ],
            profesores: [
                {
                    icon: "libro2-o",
                    name: "Blog",
                    route: "/blog"
                },
                {
                    icon: "editar-prueba",
                    name: "Evaluar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "upload",
                    name: "Corregir",
                    route: "/evaluacion_instancias/subir"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/evaluaciones/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase",
                    route: "/plan_clases"
                },
                {
                    icon: "megafono-o",
                    name: "Mis noticias",
                    route: "/noticias"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase predefinido",
                    route: "/plan_clases_predefinidos"
                },
                {
                    icon: "dialogos",
                    name: "Comunidad",
                    route: "/dudas_categorias"
                },
                {
                    icon: "dialogos",
                    name: "Centro de ayuda",
                    route: "/ayudas"
                }
            ]
        },
        showcasesByApp: null,
        menuConfig: {
            tipo: "horizontal",
            enableShowcaseMenuHomeAlumnos: false,
            enableShowcaseMenuHomeDocentes: false
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            id: null,
            asignatura: "Matemática",
            icono: "matematica-5",
            clase: "matematica",
            abreviacion: "Matemática",
            asignaturaPlataforma: "Matemática",
            evaluacionTipo: ["Enem", "diagnostico Enem"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Ciencias Naturales",
            icono: "ciencias-5",
            clase: "ciencias",
            abreviacion: "Ciências da Natureza",
            asignaturaPlataforma: "Ciências da Natureza",
            evaluacionTipo: ["Enem", "diagnostico Enem"],
            materialVisible: true
        },
        {
            asignatura: "Lenguaje y Códigos",
            icono: "lenguaje-5",
            clase: "lenguaje",
            abreviacion: "Linguagens e Códigos",
            asignaturaPlataforma: "Linguagens e Códigos",
            evaluacionTipo: ["Enem", "diagnostico Enem"],
            materialVisible: true
        },
        {
            asignatura: "Ciencias Humanas",
            icono: "historia-5",
            clase: "historia",
            abreviacion: "Ciências Humanas",
            asignaturaPlataforma: "Ciências Humanas",
            evaluacionTipo: ["Enem", "diagnostico Enem"],
            materialVisible: true
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "AprendoLivre",
        id: null,
        pais: "brasil",
        lugarTipo: "Municipio",
        lugarLabel: "Municipio",
        identificadorUsuario: ["CPF"],
        identificadorEstablecimiento: ["inep_mec"],
        grupoUsuarioAlias: "turma",
        clasificacionTipoNivel: "curso",
        lugarTipoColegio: "Municipio",
        hojaRespuestaEjemploLink:
            "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/latex/hoja_aprendolivre.pdf",
        info: {
            social: {
                facebook: null,
                twitter: null,
                youtube: "https://www.youtube.com/channel/UCkTdvl4f8bM4cLfvjas7wbg",
                instagram: "https://www.instagram.com/aprendolivre/"
            },
            soporte: {
                mail: null,
                telefono: null
            },
            contacto: {
                mail: null,
                telefono: null
            },
            companyName: "Aprendolivre",
            companySite: "www.aprendolivre.com.br"
        },
        evaluacionTipoAdaptiveDefault: "diagnostico Enem",
        evaluacionDefault: "Enem",
        evaluacionResponder: "estandar",
        resumenGeneral: {
            curriculum: "curricular",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["Enem", "diagnostico Enem"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: "Enem",
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "Enem",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Testes"
            },
            {
                tipo: "duda",
                label: "Dúvidas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respostas corretas dúvidas"
            },
            {
                tipo: "respuesta_duda",
                label: "Dúvidas de respostas"
            },
            {
                tipo: "retroalimentacion",
                label: "Feedbacks"
            }
        ],
        sesionAlias: null,
        showAyuda: null,
        enableBiblioteca: null,
        showMisCarreras: null,
        hideReforzamientos: true,
        identificadorUsuarioAlias: null,
        locale: "pt",
        loginGoogle: true,
        enableAchievements: true,
        nombreSesiones: "Aulas",
        planPersonalesDisableNivel: true,
        evaluacionInstanciasRoute: "enem/instancias"
    } as PlataformaType

    evaluaciones = {
        Enem: {
            alias: "Prova",
            nombreTipoCalificacion: "%",
            layaoutResultadoCalificacion: "horizontal",
            escalaEvaluacion: "pontuação",
            cursos: [
                {
                    label: "Diplomado",
                    clasificacion: "Egresado"
                }
            ],
            baseUrl: "/enem",
            cursoTipo: "curso",
            estrellas: [],
            cursosMaterial: [],
            clasificaciones: {
                groupBy: ["Disciplina Brasil,Macrotema Brasil,Microtema Brasil"],
                methods: "clasificaciones",
                estadisticas: ["Disciplina Brasil", "Macrotema Brasil", "Microtema Brasil"],
                referencesTo: {
                    "Macrotema Brasil": "Disciplina Brasil",
                    "Microtema Brasil": "Macrotema Brasil"
                },
                configuration: [
                    {
                        name: "Disciplina",
                        htmlId: "Disciplina_Brasil",
                        hasHijos: true,
                        clasificacionTipo: "Disciplina Brasil"
                    },
                    {
                        name: "Macrotema",
                        htmlId: "Macrotema_Brasil",
                        hasHijos: true,
                        clasificacionTipo: "Macrotema Brasil"
                    },
                    {
                        name: " Microtema",
                        htmlId: "Microtema_Brasil",
                        clasificacionTipo: "Microtema Brasil"
                    }
                ],
                cambiarPregunta: [],
                desempenoEvaluacion: "Disciplina Brasil,Macrotema Brasil,Microtema Brasil",
                estadisticasEvolutivas: ["Macrotema Brasil"],
                clasificacionTiposTablaEspecificaciones: ["Macrotema Brasil"]
            },
            cantidadPreguntas: 4,
            rangoCalificacion: {
                max: 10000,
                min: 0,
                tickInterval: 100
            },
            clasificacionMaterial: {
                label: "Macrotema",
                clasificacion: "Macrotema Brasil"
            },
            streamingChannelAlias: "Aulas ao vivo",
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Macrotema Brasil",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["Diplomado"],
            planPersonalClasificacionMateriales: [
                {
                    label: "Macrotema",
                    clasificacion: "Macrotema Brasil"
                }
            ]
        },
        "diagnostico Enem": {
            alias: "Prova",
            nombreTipoCalificacion: "%",
            layaoutResultadoCalificacion: "horizontal",
            cursos: [
                {
                    label: "Diplomado",
                    clasificacion: "Egresado"
                }
            ],
            baseUrl: "/enem",
            cursoTipo: "curso",
            estrellas: [],
            cursosMaterial: [],
            clasificaciones: {
                groupBy: ["Disciplina Brasil,Macrotema Brasil,Microtema Brasil"],
                methods: "clasificaciones",
                estadisticas: ["Disciplina Brasil", "Macrotema Brasil", "Microtema Brasil"],
                referencesTo: {
                    "Macrotema Brasil": "Disciplina Brasil",
                    "Microtema Brasil": "Macrotema Brasil"
                },
                configuration: [
                    {
                        name: "Disciplina",
                        htmlId: "Disciplina_Brasil",
                        hasHijos: true,
                        clasificacionTipo: "Disciplina Brasil"
                    },
                    {
                        name: "Macrotema",
                        htmlId: "Macrotema_Brasil",
                        hasHijos: true,
                        clasificacionTipo: "Macrotema Brasil"
                    },
                    {
                        name: " Microtema",
                        htmlId: "Microtema_Brasil",
                        clasificacionTipo: "Microtema Brasil"
                    }
                ],
                cambiarPregunta: [],
                desempenoEvaluacion: "Disciplina Brasil,Macrotema Brasil,Microtema Brasil",
                estadisticasEvolutivas: ["Macrotema Brasil"],
                clasificacionTiposTablaEspecificaciones: ["Macrotema Brasil"]
            },
            cantidadPreguntas: 4,
            rangoCalificacion: {
                max: 10000,
                min: 0,
                tickInterval: 100
            },
            clasificacionMaterial: {
                label: "Macrotema",
                clasificacion: "Macrotema Brasil"
            },
            streamingChannelAlias: "Aulas ao vivo",
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Macrotema Brasil",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["Diplomado"],
            planPersonalClasificacionMateriales: [
                {
                    label: "Macrotema",
                    clasificacion: "Macrotema Brasil"
                }
            ]
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o CPF",
        invalidLoginId: "Você deve incluir um e-mail o CPF",
        invalidLoginMessage1:
            "<strong>A combinação de e-mail / senha ou CPF / senha está incorreta.</strong> As senhas do ",
        invalidLoginMessage2:
            ' diferenciam maiúsculas de minúsculas. Verifique a tecla "Bloq mayús". Você também pode tentar limpar o cache e os cookies do seu navegador.'
    }
}
