import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { I18nService } from "@puntaje/shared/core"

@Component({
    selector: "estudiar",
    templateUrl: "./estudiar.component.html"
})
export class EstudiarComponent implements OnInit {
    itemsMenu = [
        {
            name: this.translatorService.translate("estudiar.itemsmenu.plan_estudios", {}),
            route: "/planes_estudio"
        },
        {
            name: this.translatorService.translate("estudiar.itemsmenu.plan_personales", {}),
            route: "/planes_personales",
            params: { asignatura_id: 1 }
        },
        {
            name: this.translatorService.translate("estudiar.itemsmenu.streaming.prueba_transicion", {}),
            params: { tipo: "Enem" },
            route: "/streaming/enem"
        },
        {
            name: this.translatorService.translate("estudiar.itemsmenu.biblioteca.prueba_transicion", {}),
            route: "/biblioteca",
            params: { tipo_instrumento: "Enem" }
        }
    ]
    horarioStreaming: string =
        "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/aprendolivre/horarioStreamingNov2021.png"
    constructor(protected translatorService: I18nService, protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }
}
