import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { I18nService } from "@puntaje/shared/core"

@Component({
    selector: "practicar",
    templateUrl: "./practicar.component.html"
})
export class PracticarComponent implements OnInit {
    config: typeof config
    itemsMenu = [
        {
             route: "/enem/generar",
             params: { tipo_instrumento: "Enem" },
             name: this.translatorService.translate("practicar.itemsmenu.hacer_ensayo", {})
        },
        {
            route: "/enem/recomendados",
            params: { tipo_instrumento: "Enem" },
            name: this.translatorService.translate("practicar.itemsmenu.hacer_ensayo_recomendados", {})
        }
    ]
    constructor(protected translatorService: I18nService, protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.config = config

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }
}
