import { Component, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { LoginComponent } from "@puntaje/puntaje/new-modules/usuarios"

@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    config: typeof config
    private windowHeight: number
    landingImage: string =
        "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/aprendolivre/Imagen+principal.png"
    asignaturas = [
        {
            icono: "matematica-5",
            name: "Matemática",
            nombre: "matematica"
        },
        { icono: "lenguaje-5", name: "Linguagens e Códigos", nombre: "lenguaje" },
        { icono: "ciencias-5", name: "Ciências da Natureza", nombre: "ciencias" },
        { icono: "historia-5", name: "Ciências Humanas", nombre: "historia" }
    ]
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public authService: AuthService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.config = config
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }
        this.resizeElements()
    }

    goToRegister() {
        this.router.navigate(["/usuarios/registro"])
    }

    resizeElements() {
        this.windowHeight = window.innerHeight
        var cajas = document.querySelectorAll(".caja-inicio"),
            i
        ;(<HTMLElement>cajas[0]).style.minHeight = this.windowHeight + "px"
    }
}
