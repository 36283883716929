import { Component, Directive, ViewChild } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Template simple para páginas para usuario sin tipo de usuario en particular.
Implementa una sección de contenido y un título.

shared-layout-titulo
shared-layout-contenido

#################################*/

@Component({
    templateUrl: "shared_layout.component.html",
    selector: "shared-layout",
    styleUrls: ["shared_layout.component.scss"]
})
export class SharedLayoutComponent {
    config: typeof config = config
    logo: string
    landingImage: string = "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"
    @ViewChild("sharedLayoutContenido") sharedLayoutContenido
    @ViewChild("sharedLayoutContentOverTitle", { static: true }) sharedLayoutContentOverTitle
    showContenido: boolean
    logoFooter: string

    constructor() {
        this.landingImage = this.config.app.baseStyle?.sharedLayout?.backgroundImage
            ? this.config.app.baseStyle.sharedLayout.backgroundImage
            : "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"
        this.logo = this.config.app.baseStyle?.sharedLayout?.logo
            ? this.config.app.assets[this.config.app.baseStyle?.sharedLayout?.logo]
            : this.config.app.assets.logoLargeLight
        this.logoFooter = this.config.app.baseStyle?.landing?.logoFooter
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logoFooter]
            : this.config.app.assets.logoMediumLight
    }
}
