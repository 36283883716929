<ll-titulo>{{ "estudiar.titulo" | t }}</ll-titulo>

<div class="row align-item-start">
    <div class="col-md-4 col-sm-4 col-xs-12">
        <sub-menu [itemsMenu]="itemsMenu"></sub-menu>
    </div>
    <div class="col-md-8 col-sm-8 col-xs-12">
        <!-- <calendario-streaming-showcasebox [horarioStreaming]="horarioStreaming"></calendario-streaming-showcasebox> -->
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <material-favorito-showcasebox></material-favorito-showcasebox>
            </div>
            <div class="col-md-6 col-sm-12">
                <arte-home-showcasebox-barra pagina="estudiar" zona="showcasebox1"></arte-home-showcasebox-barra>
            </div>
        </div>
    </div>
</div>
