import { PuntajeBlogModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { BlogModule } from "@puntaje/puntaje/new-modules/blog"

import { alumnosBlogRouting } from "./alumnos_blog.routing"
import { UtilModule, ModelViewModule } from "@puntaje/shared/core"

import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AlumnosLayoutsModule,
        UtilModule,
        BannersModule,
        ModelViewModule,
        BlogModule,
        alumnosBlogRouting,
        PuntajeBlogModule
    ],
    declarations: [],
    exports: []
})
export class AlumnosBlogModule {}
