<ll-titulo>{{ "progreso.titulo" | t }}</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <sub-menu [itemsMenu]="itemsMenu"></sub-menu>
    </div>
    <div class="col-md-4 col-sm-6">
        <resumen-estadisticas-showcasebox></resumen-estadisticas-showcasebox>
    </div>
    <div class="col-md-4 col-sm-6">
        <ensayos-recientes-showcasebox
            [link]="'/enem/instancias/'"
            [urlResultados]="'/enem/resultados/'"
            [evaluacionTipo]="'Enem'"
        ></ensayos-recientes-showcasebox>
        <grafico-mi-progreso-showcasebox [evaluacionTipo]="'Enem'"></grafico-mi-progreso-showcasebox>
    </div>
</div>
