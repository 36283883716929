import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from "@angular/core"

import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { AuthService } from "@puntaje/shared/core"
import { Router } from "@angular/router"

/*#################################

Menú arriba del sitio.

#################################*/

@Component({
    templateUrl: "topmenu.component.html",
    selector: "topmenu",
    styleUrls: ["topmenu.component.scss"]
})
export class TopmenuComponent extends MenuComponent {
    @Output() toggleTopmenuEvent = new EventEmitter()
    @ViewChild("topmenu") insideElement
    catchOutsideClick: boolean = false
    itemsMenu: any[]
    ITEMS_MENU = [
        {
            name: "topmenu.home",
            route: "/home"
        },
        {
            name: "topmenu.estudiar",
            route: "/estudiar"
        },
        {
            name: "topmenu.practicar",
            route: "/practicar"
        },
        {
            name: "topmenu.tu_progreso",
            route: "/tu_progreso"
        },
        {
            name: "topmenu.orientacion",
            route: "/orientacion"
        },
        {
            name: "topmenu.participa",
            route: "/participa"
        }
    ]

    @Input() config: any

    constructor(store: Store<State>, protected authService: AuthService, protected router: Router) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        /*if (this.authService.getEstablecimientos().length != 0) {
            let item = {
                name: "Reportes",
                route: "/estadisticas/resumen_uso",
                icon: "establecimiento-o"
            }
            this.ITEMS_MENU.push(item)
        }*/
        setTimeout(() => {
            this.catchOutsideClick = true
        }, 300)
        this.itemsMenu = this.ITEMS_MENU
    }
    onMenuClickOutside() {
        this.toggleTopmenuEvent.emit()
    }

    close() {
        this.toggleTopmenuEvent.emit()
    }
}
