import { PuntajeAyudaModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { AyudaModule as AyudaMod } from "@puntaje/puntaje/new-modules/ayuda"
import { UtilModule } from "@puntaje/shared/core"

import { alumnosAyudaRouting } from "./alumnos_ayuda.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AlumnosLayoutsModule,
        alumnosAyudaRouting,
        AyudaMod,
        UtilModule,
        PuntajeAyudaModule
    ],
    declarations: [],
    exports: [],
    providers: []
})
export class AlumnosAyudaModule {}
