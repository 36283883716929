import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"

@Component({
    selector: "orientacion",
    templateUrl: "./orientacion.component.html"
})
export class OrientacionComponent implements OnInit {
    itemsMenu = [
        /*{
            route: "/mis-carreras",
            name: "Mis carreras y simulador"
        },*/
        {
            route: "/buscador-carreras",
            name: "Pesquisar Cursos de Formação"
        },
        /*{
            name: "Test vocacional"
        },*/
        {
            route: "/buscador-universidades",
            name: "Procurar universidade"
        },
        {
            route: "/blog",
            name: "Blog"
        }
    ]
    constructor(protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }
}
