/** @format */

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"

import { AlumnosLayoutsModule } from "./layouts/alumnos_layouts.module"
import { AlumnosLandingModule } from "./landing/alumnos_landing.module"
import { AlumnosUsuariosModule } from "./usuarios/alumnos_usuarios.module"
import { AlumnosEnsayosModule } from "./ensayos/alumnos_ensayos.module"
import { AlumnosMaterialesModule } from "./materiales/alumnos_materiales.module"
import { AlumnosEstadisticasModule } from "./estadisticas/alumnos_estadisticas.module"
import { AlumnosNotificacionesModule } from "./notificaciones/alumnos_notificaciones.module"
import { AlumnosPlanEstudiosModule } from "./plan_estudios/alumnos_plan_estudios.module"
import { AlumnosDudasModule } from "./dudas/alumnos_dudas.module"
import { AlumnosAyudaModule } from "./ayuda/alumnos_ayuda.module"
import { AlumnosCarrerasModule } from "./carreras/alumnos_carreras.module"
import { AlumnosPlanPersonalesModule } from "./plan_personales/alumnos_plan_personales.module"
import { AlumnosPlanClasesModule } from "./plan-clases/alumnos-plan-clases.module"
import { AlumnosBlogModule } from "./blog/alumnos_blog.module"
import { AlumnosUniversidadModule } from "./universidad/alumnos-universidad.module"
import { AlumnosStreamingModule } from "./streaming/alumnos_streaming.module"
import { AlumnosEntrenadorModule } from "./entrenador/alumnos_entrenador.module"
import { config } from "../config/config"
import { environment } from "../environments/environment"
import { AppComponent, getProviders, PuntajeCoreModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        AlumnosLayoutsModule,
        AlumnosLandingModule,
        AlumnosUsuariosModule,
        AlumnosEnsayosModule,
        AlumnosMaterialesModule,
        AlumnosEstadisticasModule,
        AlumnosNotificacionesModule,
        AlumnosPlanEstudiosModule,
        AlumnosDudasModule,
        AlumnosAyudaModule,
        AlumnosCarrerasModule,
        AlumnosPlanPersonalesModule,
        AlumnosPlanClasesModule,
        AlumnosBlogModule,
        AlumnosStreamingModule,
        AlumnosEntrenadorModule,
        AlumnosUniversidadModule,
        PuntajeCoreModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    providers: getProviders(config, environment)
})
export class AppModule {}
