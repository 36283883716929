<cui-logged-layout [freeContentStyle]="false">
    <cui-layout-header>
        <ng-container *ngIf="authService.isLoggedIn()">
            <navbar></navbar>
            <topmenu></topmenu>
        </ng-container>
    </cui-layout-header>
    <cui-layout-footer>
        <footer-component></footer-component>
        <popup-cookies></popup-cookies>
        <typeform-widgets-view></typeform-widgets-view>
    </cui-layout-footer>
</cui-logged-layout>
