import { Validations, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { Lugar } from "./lugares.model"
import { UsuarioRegistroBase } from "./usuarios_registro_base.model"
import { Establecimiento } from "./establecimientos.model"
import { UsuarioBrasil } from "./usuario_brasil.model"
import { Pais } from "./paises.model"

export class UsuarioRegistroBrasil extends UsuarioRegistroBase {
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public email: string
    public password: string
    public password_confirmation: string
    public lugar_id: number

    public cpf: string
    public telefono: string
    public year_egreso: number

    @SaveType(() => Pais) public pais: Pais
    @SaveType(() => Lugar) public lugar: Lugar
    @SaveType(() => Establecimiento) public colegio: Establecimiento

    public static global_validations = [Validations.equalPasswords(["password", "password_confirmation"])]

    constructor() {
        super()
        this.colegio = new Establecimiento()
    }

    public nombreCompleto() {
        return this.nombre + " " + this.apellido_paterno + " " + this.apellido_materno
    }

    public toString() {
        return this.cpf
    }

    public toUsuario() {
        var user = new Usuario()
        user.nombre = this.nombre
        user.apellido_paterno = this.apellido_paterno
        user.apellido_materno = this.apellido_materno
        user.email = this.email
        user.password = this.password
        user.password_confirmation = this.password_confirmation
        if (this.pais && this.pais.id) {
            user.pais_id = this.pais.id
        }
        user.rol_id = 3 //Generalizar, debería siempre ser 3 (alumno)
        user.lugar_id = this.lugar_id
        user.usuario_chile = null
        user.usuario_brasil = new UsuarioBrasil()
        user.usuario_brasil.cpf = this.usuario_brasil.cpf
        user.usuario_brasil.telefono = this.usuario_brasil.telefono
        user.usuario_brasil.year_egreso = this.usuario_brasil.year_egreso

        return user
    }
}
