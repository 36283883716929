import { urls } from "../config/urls.prod"
import { AppEnvironment } from "@puntaje/shared/core"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.nebuUrl,
        achievements: urls.achievementsUrl,
        carreras: urls.carrerasUrl
    },
    googleAnalyticsPropertyId: {},
    globalSiteTag: {
        alumnos: {
            propertyId: ["G-7XGDRLDPZF", "AW-10782692287"],
            sendTo: {
                registro_social: "AW-10782692287/yvhXCNrBvoEDEL-ny5Uo",
                registro: "AW-10782692287/E2FnCNjIvoEDEL-ny5Uo"
            }
        }
    },
    domain: {
        profesores: "docentes.aprendolivre.com.br",
        alumnos: "www.aprendolivre.com.br",
        admin: "admin.aprendolivre.com.br"
    },
    facebookAnalyticsPropertyId: {},
    facebook: {
        appId: "859012114594496",
        apiVersion: "v11.0"
    }
}

export const oldEnvironment = {
    production: true,
    baseUrl: urls.baseUrl,
    nebuUrl: urls.nebuUrl,
    achievementsUrl: urls.achievementsUrl,
    facebookAppId: null,
    facebookApiVersion: null,
    domain: {
        profesores: "docentes.aprendolivre.com.br",
        alumnos: "www.aprendolivre.com.br",
        admin: "admin.aprendolivre.com.br"
    }
}
