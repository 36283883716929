import { PuntajePlanPersonalesModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"

import { routing } from "./alumnos_plan_personales.routing"

import { PlanificacionesModule } from "@puntaje/puntaje/new-modules/planificaciones"
import { PlanPersonalesModule } from "@puntaje/puntaje/new-modules/plan-personales"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"

import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"

import { UtilModule } from "@puntaje/shared/core"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AlumnosLayoutsModule,
        PlanificacionesModule,
        PlanPersonalesModule,
        routing,
        EnsayosModule,
        SharedModule,
        UtilModule,
        PuntajePlanPersonalesModule
    ],
    exports: [],
    declarations: [],
    providers: []
})
export class AlumnosPlanPersonalesModule {}
