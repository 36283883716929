import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { SessionService, I18nService, AuthService, TitleService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Usuario, Usuarios } from "@puntaje/puntaje/api-services"

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent implements OnInit {
    config: typeof config
    usuario_id: string
    @Input() pagina: string = "home"
    isPro = false
    hasGrupoUsuarios: boolean
    dias = false
    dias_faltantes = 0
    frase: string = ""
    mailSinConfirmar: boolean = false
    showMisCarreras: boolean = false || config.plataforma.showMisCarreras
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;
    asignaturasBoxInsteadPlanClase = false

    itemsMenu: any = [
        // {
        //     route: "/enem/generar",
        //     params: { tipo_instrumento: "enem" },
        //     name: this.translatorService.translate("home.itemsmenu.generar_ensayo", {})
        // },
        {
            name: this.translatorService.translate("home.itemsmenu.streaming", {}),
            params: { tipo: "Enem" },
            route: "/streaming/enem"
        },
        {
            name: this.translatorService.translate("home.itemsmenu.plan_estudios", {}),
            route: "/planes_estudio"
        }
    ]

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private authService: AuthService,
        protected sessionService: SessionService,
        protected usuariosService: Usuarios,
        protected translatorService: I18nService,
        protected loggedLayoutService: LoggedLayoutService,
        protected titleService: TitleService
    ) {
        this.config = config
        let usuario = this.authService.getUserData()
        this.usuario_id = usuario.id ? usuario.id + "" : "1"
    }

    ngOnInit() {
        this.itemsMenu.push({
            name: this.translatorService.translate("home.itemsmenu.logros", {}),
            params: { tab: "logros" },
            route: "/usuarios/" + this.usuario_id
        })
        this.isPro = this.sessionService.isPro()
        this.hasGrupoUsuarios = this.sessionService.getGrupoUsuariosActivos().length > 0
        this.setMailSinConfirmar()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })

        this.titleService.setTitle("Home")
    }

    setMailSinConfirmar() {
        const confirmed_at = this.sessionService.getConfirmedAt()
        if (!confirmed_at) {
            this.mailSinConfirmar = true
        }
    }
}
