import { Component, OnInit } from "@angular/core"
import { I18nService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./registro-albr.component.html",
    styleUrls: ["./registro-albr.component.scss"]
})
export class RegistroAlBrComponent implements OnInit {
    tituloDeslogueado: string

    constructor(private translatorService: I18nService) {
        this.tituloDeslogueado = this.translatorService.translate("landing.registro.tituloDeslogueado", {})
    }

    ngOnInit() {}

    onTitleChange(titulo) {
        this.tituloDeslogueado = titulo
    }
}
