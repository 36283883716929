<div class="content-menu">
    <nav class="navbar clearfix">
        <div class="navbar-header">
            <div class="navbar-left navbar-logo-container">
                <a
                    href="#section1"
                    easyScrollTo
                    [easyScrollToPadding]="0"
                    [easyScrollToDuration]="800"
                    [easyScrollToEasing]="'easeInOutExpo'"
                >
                    <img
                        class="navbar-logo"
                        src="assets/img/logos/logo.png"
                        alt="{{ config.plataforma.info.companyName }}"
                    />
                </a>
            </div>
            <div class="toggle-wrap">
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
            </div>
        </div>
        <div class="menu-right navbar-collapse collapse">
            <ul class="nav navbar-nav">
                <li>
                    <a
                        href="#section1"
                        easyScrollTo
                        [easyScrollToPadding]="0"
                        [easyScrollToDuration]="800"
                        [easyScrollToEasing]="'easeInOutExpo'"
                    >
                        {{ "landing.menu.pagina_inicial" | t }}
                    </a>
                </li>
                <li>
                    <a
                        href="#section2"
                        easyScrollTo
                        [easyScrollToPadding]="0"
                        [easyScrollToDuration]="800"
                        [easyScrollToEasing]="'easeInOutExpo'"
                    >
                        {{ "landing.menu.provas_enem" | t }}
                    </a>
                </li>
                <li>
                    <a
                        href="#section3"
                        easyScrollTo
                        [easyScrollToPadding]="0"
                        [easyScrollToDuration]="800"
                        [easyScrollToEasing]="'easeInOutExpo'"
                    >
                        {{ "landing.menu.como_funciona" | t }}
                    </a>
                </li>
                <li>
                    <a
                        href="#section4"
                        easyScrollTo
                        [easyScrollToPadding]="0"
                        [easyScrollToDuration]="800"
                        [easyScrollToEasing]="'easeInOutExpo'"
                    >
                        {{ "landing.menu.aulas_vivo" | t }}
                    </a>
                </li>
                <li>
                    <a
                        href="#section5"
                        easyScrollTo
                        [easyScrollToPadding]="0"
                        [easyScrollToDuration]="800"
                        [easyScrollToEasing]="'easeInOutExpo'"
                    >
                        {{ "landing.menu.contacto" | t }}
                    </a>
                </li>
                <li>
                    <button class="btn btn_style btn-default btn-lg btn-block" (click)="goToRegister()">
                        {{ "landing.menu.comenzar" | t }}
                    </button>
                </li>
            </ul>
        </div>
    </nav>
</div>
<div class="contentLine">
    <div class="line"></div>
</div>
