<ng-container *ngIf="!saved">
    <form
        autocomplete="off"
        novalidate
        [formGroup]="form"
        easyScroll
        [scrollOnVariableChange]="currentStep"
        [easyScrollPadding]="0"
    >
        <h3>{{ "landing.registro.titulo" | t }}</h3>
        <ng-container *ngIf="currentStep == 0">
            <form-input
                [form]="form"
                [params]="params['id']"
                [(value)]="usuarioRegistro['id']"
                [key]="'id'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['nombre']"
                [(value)]="usuarioRegistro['nombre']"
                [key]="'nombre'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['apellido_paterno']"
                [(value)]="usuarioRegistro['apellido_paterno']"
                [key]="'apellido_paterno'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['apellido_materno']"
                [(value)]="usuarioRegistro['apellido_materno']"
                [key]="'apellido_materno'"
            ></form-input>
            <form-input
                [form]="usuarioPaisForm"
                [params]="usuarioPaisParams['usuario_id']"
                [(value)]="usuarioRegistro['usuario_id']"
                [key]="'usuario_id'"
            ></form-input>
            <form-input
                [form]="usuarioPaisForm"
                [params]="usuarioPaisParams['cpf']"
                [(value)]="usuarioRegistro['usuario_' + pais]['cpf']"
                [key]="'cpf'"
            ></form-input>
        </ng-container>
        <ng-container *ngIf="currentStep == 1">
            <form-input
                [form]="form"
                [params]="params['email']"
                [(value)]="usuarioRegistro['email']"
                [key]="'email'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['password']"
                [enablePasswordBar]="true"
                [(value)]="usuarioRegistro['password']"
                [key]="'password'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['password_confirmation']"
                [(value)]="usuarioRegistro['password_confirmation']"
                [key]="'password_confirmation'"
            ></form-input>
        </ng-container>
        <ng-container *ngIf="currentStep == 2">
            <form-input
                [form]="usuarioPaisForm"
                [params]="usuarioPaisParams['telefono']"
                [(value)]="usuarioRegistro['usuario_' + pais]['telefono']"
                [key]="'telefono'"
            ></form-input>
            <label [attr.for]="'lugar'">{{ "landing.registro.datos_personales.municipio" | t }}</label>
            <autocomplete
                [placeholder]="'Escreva e selecione uma das opções'"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="lugar"
                [options]="filteredLugarColegio"
                (filterChange)="getLugarColegio($event)"
                (controlValue)="updateLugarColegio($event)"
                class="autocomplete"
            ></autocomplete>
            <div *ngIf="dirty && !usuarioRegistro.lugar_id">
                <ul class="error-list">
                    <li>
                        <div class="align-table">
                            <div class="left-icon">
                                <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                            </div>
                            <div class="right-message">Você deve inserir um município</div>
                        </div>
                    </li>
                </ul>
            </div>
            <form-input
                [form]="usuarioPaisForm"
                [params]="usuarioPaisParams['year_egreso']"
                [(value)]="usuarioRegistro['usuario_' + pais]['year_egreso']"
                [key]="'year_egreso'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['terminos_y_condiciones']"
                [(value)]="usuarioRegistro['terminos_y_condiciones']"
                [key]="'terminos_y_condiciones'"
            ></form-input>
        </ng-container>

        <div class="btn-container">
            <span *ngIf="currentStep == 0"></span>
            <cui-button (click)="previousStep()" type="button" class="previous-btn" *ngIf="currentStep > 0">
                <fa name="arrow-left" class="left-icon"></fa>
                Anterior
            </cui-button>
            <cui-button (click)="nextStep()" type="button" class="next-btn" *ngIf="currentStep < steps.length - 1">
                Próximo
                <fa name="arrow-right" class="right-icon"></fa>
            </cui-button>
            <cui-button (click)="save()" type="button" *ngIf="currentStep == steps.length - 1" buttonType="confirm">
                Inscrever-se
            </cui-button>
        </div>
        <cui-button (click)="back()" type="button" [isBlock]="true" buttonType="cancel">
            Retornar a página inicial
        </cui-button>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    {{ "landing.registro.msgSaved" | t }}
</ng-container>
