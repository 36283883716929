import { BaseForm, Validations, GroupValidator, I18nService } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"

import { UsuarioBrasilForm } from "./usuario_brasil.form"
import { HttpClient } from "@angular/common/http"
import { AppConfig } from "@puntaje/shared/core"

declare const config: AppConfig

export class UsuarioRegistroBrasilForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    public static get formParams(): any {
        const translatorService = this.injector.get(I18nService)
        const companyName = config.plataforma.info.companyName
        const params: any = {
            id: { label: "ID", type: "hidden", visible: false },
            nombre: {
                label: translatorService.translate("landing.registro.datos_personales.nombres", {}),
                type: "text",
                visible: true,
                placeholder: translatorService.translate("landing.registro.datos_personales.nombres", {}),
                validations: [Validators.required, Validators.minLength(2), Validations.validateNombresWithSpaces]
            },
            apellido_paterno: {
                label: translatorService.translate("landing.registro.datos_personales.apellido_paterno", {}),
                type: "text",
                visible: true,
                placeholder: translatorService.translate("landing.registro.datos_personales.apellido_paterno", {}),
                validations: [Validators.required, Validators.minLength(2), Validations.validateString]
            },
            apellido_materno: {
                label: translatorService.translate("landing.registro.datos_personales.apellido_materno", {}),
                type: "text",
                visible: true,
                placeholder: translatorService.translate("landing.registro.datos_personales.apellido_materno", {}),
                validations: [Validators.minLength(2), Validations.validateString]
            },
            email: {
                label: translatorService.translate("landing.registro.datos_personales.email", {}),
                type: "text",
                visible: true,
                placeholder: "correo@mail.com",
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmail }],
                customError: "Esse email já existe"
            },
            password: {
                label: translatorService.translate("landing.registro.datos_personales.password", {}),
                type: "password",
                visible: true,
                placeholder: translatorService.translate("landing.registro.datos_personales.password", {}),
                validations: [Validators.required, Validators.minLength(8)],
                serviceValidators: [{ class: HttpClient, validator: Validations.checkPasswordBreach }]
            },
            password_confirmation: {
                label: translatorService.translate("landing.registro.datos_personales.confirmar_password", {}),
                type: "password",
                visible: true,
                placeholder: translatorService.translate("landing.registro.datos_personales.confirmar_password", {}),
                global: true,
                validations: [Validators.required, Validators.minLength(8)]
            },
            terminos_y_condiciones: {
                innerhtml: `Li e aceito as <a href='/condicionesUso' target='_blank'> condições gerais de uso de ${companyName} </a>`,
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Você deve aceitar os termos gerais de uso de " + companyName
            },
            lugar: {
                label: translatorService.translate("landing.registro.datos_personales.municipio", {}),
                type: "autocomplete",
                visible: true,
                placeholder: "Escribe y selecciona entre las opciones",
                options: { class: Lugares, params: { lugar_tipo: "Municipio", pais: config.plataforma.pais } }
            },
            usuario_brasil: { type: "model", class: UsuarioBrasilForm }
        }

        if (config.registro && config.registro.enablePoliticasPrivacidad) {
            params["politicas_privacidad"] = {
                innerhtml:
                    "He leído y acepto las <a href='/politicas_privacidad_y_proteccion_datos' target='_blank'>políticas de privacidad y protección de datos personales</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar las políticas de privacidad y protección de datos personales"
            }
        }

        return params
    }
}
