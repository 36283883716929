import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { I18nService } from "@puntaje/shared/core"

@Component({
    selector: "tu-progreso",
    templateUrl: "./tu-progreso.component.html"
})
export class TuProgresoComponent implements OnInit {
    itemsMenu = [
        {
            route: "/enem/resultados",
            params: { tipo_instrumento: "Enem" },
            name: this.translatorService.translate("progreso.itemsmenu.historial", {})
        },
        {
            route: "/enem/estadisticas",
            params: { tipo_instrumento: "Enem" },
            name: this.translatorService.translate("progreso.itemsmenu.estadisticas", {})
        },
        {
            route: "/enem/mi_progreso",
            params: { tipo_instrumento: "Enem" },
            name: this.translatorService.translate("progreso.itemsmenu.evolucion", {})
        }
    ]
    constructor(protected translatorService: I18nService, protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }
}
