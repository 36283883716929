<nav class="navbar navbar-default">
    <div class="navbar-collapse collapse nav-justified-menu">
        <ul class="nav navbar-nav">
            <ng-container *ngFor="let item of itemsMenu">
                <li routerLinkActive="active-menu">
                    <a [routerLink]="item.route" role="button">
                        {{ item.name | t }}
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</nav>
