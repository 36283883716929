import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { DomSanitizer } from "@angular/platform-browser"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Barra de navegación que va en la parte superior.

#################################*/

@Component({
    templateUrl: "navbar-public.component.html",
    selector: "navbar-public",
    styleUrls: ["navbar-public.component.scss"]
})
export class NavbarPublicComponent {
    contactMail: string
    companyName: string
    config: typeof config

    constructor(protected router: Router, protected sanitizer: DomSanitizer) {
        this.contactMail = config.plataforma.info.contacto.mail
        this.companyName = config.plataforma.info.companyName
        this.config = config
    }

    sanitize(input: string): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(input)
    }

    goToAyuda() {
        this.router.navigate(["/ayudas"])
    }
}
