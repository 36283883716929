<nav class="navbar" aria-label="Navegación Usuario">
    <div class="container-fluid">
        <div class="navbar-content">
            <div class="navbar-left">
                <button type="button" class="topmenu-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <div class="navbar-logo-container">
                    <a routerLink="/home">
                        <img
                            class="navbar-logo"
                            src="{{ config.app.assets.logoSmall }}"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                        <img
                            class="navbar-logo-res"
                            src="{{ config.app.assets.logoSquare }}"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                    </a>
                </div>
            </div>
            <div class="user-menu">
                <ul class="nav navbar-top-links navbar-right">
                    <li>
                        <a routerLink="/home" role="button">
                            <ogr-icon class="centered-icons" name="home-o"></ogr-icon>
                        </a>
                    </li>
                    <li>
                        <span notificaciones-on-navbar class="icon-btn">
                            <span class="centered-icons alerta-container">
                                <ogr-icon name="campana-o"></ogr-icon>
                                <alerta-notificaciones class="alerta"></alerta-notificaciones>
                            </span>
                        </span>
                    </li>
                    <li class="li-username">
                        <a (click)="goToProfile()">
                            <span>
                                {{ usuario_nombre | easyplaceholder: "Usuario" }}
                            </span>
                            <span
                                *ngIf="nivel"
                                class="nivel"
                                [attr.title]="ptosToNextLvl + ' ptos para el siguiente nivel'"
                            >
                                {{ nivel }}
                            </span>
                        </a>
                    </li>
                    <li>
                        <a (click)="goToAyuda()">Ajuda</a>
                    </li>

                    <li class="dropdown last-list-item">
                        <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                            <div class="clearfix last-item-cont">
                                <div class="user-circle-container pull-left" [class.noImage]="!usuario_avatar">
                                    <img *ngIf="usuario_avatar" class="avatar" [src]="usuario_avatar | trustedurl" />
                                    <fa *ngIf="!usuario_avatar" class="fa-placeholder" [name]="'user'"></fa>
                                    <nivel-circlebar (onReady)="setNivel($event)"></nivel-circlebar>
                                </div>
                                <div class="pull-right little-arrow-user">
                                    <fa [name]="'sort-desc'"></fa>
                                </div>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-user">
                            <li>
                                <a (click)="edit()">{{ "navbar.edit" | t }}</a>
                            </li>
                            <li>
                                <a (click)="logout()">{{ "navbar.logout" | t }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
