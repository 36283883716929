import { Component, OnInit, Output, EventEmitter, Injector, ChangeDetectorRef, Optional } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { Clasificacion } from "@puntaje/nebulosa/api-services"
import {
    Usuarios,
    Establecimiento,
    Establecimientos,
    Usuario,
    Lugar,
    Lugares,
    UsuarioRegistroBrasil,
    UsuarioRegistroBrasilForm
} from "@puntaje/puntaje/api-services"
import { BaseForm, AuthService, SessionService } from "@puntaje/shared/core"
import { RegisterStepsService, RegisterStepStatus } from "../register-steps/register-steps.service"
import { BaseRegisterStepsComponent } from "../base-register-steps/base-register-steps.component"
import { AppConfig } from "@puntaje/shared/core"
import { Login } from "@puntaje/puntaje/services"
import { Subscription } from "rxjs"
import { Angulartics2 } from "angulartics2"

declare const config: AppConfig
declare let gtag: Function
@Component({
    selector: "usuario-registro-al-br",
    templateUrl: "./register-aprendolivre-br.component.html",
    styleUrls: ["./register-aprendolivre-br.component.scss"]
})
export class RegisterAprendolivreBrComponent extends BaseRegisterStepsComponent implements OnInit {
    usuarioRegistro: UsuarioRegistroBrasil = new UsuarioRegistroBrasil()
    UsuarioRegistroBrasilForm: UsuarioRegistroBrasilForm
    params: any
    form: UntypedFormGroup
    save_button_text = "Guardar"

    saved = false

    pais: string = config.plataforma.pais
    usuarioPaisParams: any
    usuarioPaisForm: UntypedFormGroup

    egresado: Clasificacion

    lugar: Lugar
    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]
    filteredEstablecimientos: Establecimiento[]
    dirty = false
    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()

    constructor(
        protected usuariosService: Usuarios,
        protected establecimientosService: Establecimientos,
        protected router: Router,
        protected injector: Injector,
        protected cdr: ChangeDetectorRef,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected loginService: Login,
        protected lugaresService: Lugares,
        protected registerStepsService: RegisterStepsService,
        @Optional() protected angulartics2: Angulartics2
    ) {
        super(usuariosService, router, null, establecimientosService, registerStepsService)
        UsuarioRegistroBrasilForm.injector = this.injector
        this.params = UsuarioRegistroBrasilForm.formParams
        this.usuarioPaisParams = this.params["usuario_" + this.pais].class.formParams
    }

    ngOnInit() {
        super.ngOnInit()
        this.usuarioRegistro["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()

        this.form = UsuarioRegistroBrasilForm.getForm(this.usuarioRegistro, null, this.injector)

        this.usuarioPaisForm = this.form.controls["usuario_" + this.pais] as UntypedFormGroup

        this.stepValidations = {
            0: ["nombre", "apellido_paterno", ["usuario_brasil", "cpf"]],
            1: ["email", "password", "password_confirmation"],
            2: ["terminos_y_condiciones"]
        }
    }

    save() {
        if (!this.usuarioRegistro.lugar_id) return
        const usuario = this.usuarioRegistro.toUsuario()
        this.beforeSave()
        this.dirty = true
        BaseForm.markFormControlsAsTouched(this.form)
        if (this.saveCondition()) {
            this.usuariosService.save(usuario).then((usuario: Usuario) => {
                return this.afterSave(usuario)
            })
        }
    }

    beforeSave() {
        this.usuariosService.enableIgnoreModel()
    }

    afterSave(response) {
        this.usuariosService.disableIgnoreModel()
        this.gtagReportConversion()

        return this.loginService.loginGeneral(
            Promise.resolve(this.authService.loginRegistroOPassword(response)),
            res => console.log(res),
            true,
            true
        )
    }

    getLugarColegio(lugarFilter: string) {
        this.lugaresService
            .where({ per: 100, raw: 1, filter: lugarFilter, lugar_tipo: ["Municipio"], pais: this.pais })
            .then((lugares: Lugar[]) => {
                this.filteredLugarColegio = lugares
            })
    }

    updateLugarColegio(lugar: any) {
        if (lugar instanceof Lugar) {
            this.lugarColegio = lugar
            this.usuarioRegistro.lugar_id = lugar.id
        }
    }

    back() {
        this.router.navigate([""])
    }

    gtagReportConversion(url = undefined) {
        console.log("gtag report albr")
        var callback = function () {
            if (typeof url != "undefined") {
                window.location.href = url
            }
        }

        gtag("event", "conversion", { send_to: "AW-10782692287/E2FnCNjIvoEDEL-ny5Uo" })
        // this.angulartics2.eventTrack.next({
        //     action: "conversion",
        //     properties: {
        //         gstCustom: {
        //             send_to: "AW-10782692287/E2FnCNjIvoEDEL-ny5Uo",
        //             event_callback: callback
        //         }
        //     }
        // })

        return false
    }
}
