<ll-over-title>
    <noticias-aviso-home></noticias-aviso-home>

    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>
<ll-titulo>{{ "home.welcome" | t }}</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <sub-menu [itemsMenu]="itemsMenu" stepPosition="right"></sub-menu>
        <ng-container>
            <ng-template #nextBtn>
                <button type="button" class="btn btn-primary btn_style">
                    <fa name="chevron-right"></fa>
                    {{ "tour.btn.next" | t }}
                </button>
            </ng-template>
            <ng-template #prevBtn>
                <button type="button" class="btn btn-primary btn_style">
                    <fa name="chevron-left"></fa>
                    {{ "tour.btn.prev" | t }}
                </button>
            </ng-template>
            <ng-template #doneBtn>
                <button type="button" class="btn btn-default btn_style">{{ "tour.btn.ok" | t }}</button>
            </ng-template>
        </ng-container>
        <resumen-semana-showcasebox
            [tipoEvaluacion]="'Enem'"
            [linkEvaluacion]="'/enem/recomendados'"
        ></resumen-semana-showcasebox>
        <br />
        <noticias-alumnos-showcasebox></noticias-alumnos-showcasebox>
    </div>
    <div class="col-md-4 col-sm-6">
        <instrumento-mensual-showcasebox
            [tipoInstrumento]="config.plataforma.instrumentoMensualTipo"
        ></instrumento-mensual-showcasebox>
        <instrumentos-mensuales-ranking
            [tipoInstrumento]="config.plataforma.instrumentoMensualTipo"
        ></instrumentos-mensuales-ranking>
        <ng-container *ngIf="hasGrupoUsuarios">
            <grupo-usuarios-notificaciones-showcasebox
                [maximoNotificaciones]="3"
            ></grupo-usuarios-notificaciones-showcasebox>
        </ng-container>
    </div>
    <div class="col-md-4 col-sm-6">
        <arte-home-showcasebox [pagina]="pagina" zona="showcasebox0"></arte-home-showcasebox>
        <arte-home-showcasebox [pagina]="pagina" zona="showcasebox1"></arte-home-showcasebox>
    </div>
</div>

<arte-zona-modal [pagina]="pagina"></arte-zona-modal>
<noticias-modal [perfiles]="'todos,alumnos,alumnos libres,alumnos nomina'"></noticias-modal>
