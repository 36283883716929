<sl-content-over-title>
    <h2 class="subtitulo1">
        Prepare-se para o
        <em>ENEM 2023</em>
    </h2>
    <h2 class="subtitulo2">
        Completamente
        <em>GRÁTIS</em>
        , para sempre
    </h2>
    <register-steps></register-steps>
</sl-content-over-title>
<sl-titulo>
    {{ tituloDeslogueado | easyplaceholder: "Registro" }}
</sl-titulo>
<usuario-registro-al-br></usuario-registro-al-br>
