<nav class="navbar" aria-label="Navegación Usuario">
    <div class="container-fluid">
        <div class="navbar-content">
            <div class="navbar-left">
                <button type="button" class="topmenu-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <div class="navbar-logo-container">
                    <a routerLink="/home">
                        <img
                            class="navbar-logo"
                            src="{{ config.app.assets.logoSmall }}"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                        <img
                            class="navbar-logo-res"
                            src="{{ config.app.assets.logoSquare }}"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                    </a>
                </div>
            </div>
            <div class="navbar-right navbar-collapse collapse">
                <ul class="nav navbar-nav">
                    <li>
                        <button routerLink="/usuarios/registro" class="btn btn_style">
                            {{ "landing.menu.comenzar" | t }}
                        </button>
                    </li>
                    <li>
                        <a routerLink="/landing">
                            {{ "landing.menu.pagina_inicial" | t }}
                        </a>
                    </li>
                    <li>
                        <a routerLink="/public_blog">
                            {{ "landing.menu.blog" | t }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
