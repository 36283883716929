import { BaseForm, Validations, AppConfig } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { UsuariosCompletarRegistroBrasilForm } from "./usuarios_completar_registro_brasil.form"
import { UsuariosCompletarRegistroBrasilClonesForm } from "./usuarios_completar_registro_brasil_clones.form"

declare const config: AppConfig

export class UsuariosCompletarRegistroAprendolibreBrasilForm extends BaseForm {
    companyName: string = ""
    // constructor(){
    //  super();
    //  this.companyName = config.plataforma.info.companyName;
    // }

    public static get formParams(): any {
        const companyName = config.plataforma.info.companyName

        let params: any = {
            id: { label: "ID", type: "hidden", visible: false },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail]
            },
            password: {
                label: "Senha",
                type: "password",
                visible: true,
                validations: [Validators.minLength(8)]
            },
            password_confirmation: {
                label: "Confirme a nova senha",
                type: "password",
                visible: true,
                global: true,
                validations: [Validators.required, Validators.minLength(8)]
            },
            terminos_y_condiciones: {
                innerhtml:
                    `Li e aceito as <a href='/condicionesUso' target='_blank'> condições gerais de uso de ${companyName} </a>`,
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            },
        }

        let classes = {
            brasil: UsuariosCompletarRegistroBrasilClonesForm
        }

        params["usuario_" + config.plataforma.pais] = { type: "model", class: classes[config.plataforma.pais] }

        return params
    }
}
