<ng-container *ngIf="!saved">
    <form novalidate [formGroup]="form" *ngIf="form">
        <div class="row">
            <div class="col-md-6">
                <h3>Dados pessoais</h3>
                <form-input [form]="form" [params]="params['id']" [(value)]="usuario['id']" [key]="'id'"></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['usuario_id']"
                    [(value)]="usuario['usuario_' + pais]['usuario_id']"
                    [key]="'usuario_id'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['nombre']"
                    [(value)]="usuario['nombre']"
                    [key]="'nombre'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_paterno']"
                    [(value)]="usuario['apellido_paterno']"
                    [key]="'apellido_paterno'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['telefono']"
                    [(value)]="usuario['usuario_' + pais]['telefono']"
                    [key]="'telefono'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['cpf']"
                    [(value)]="usuario['usuario_' + pais]['cpf']"
                    [key]="'cpf'"
                ></form-input>
            </div>

            <div class="col-md-6">
                <label [attr.for]="'lugar'">{{ "landing.registro.datos_personales.municipio" | t }}</label>
                <autocomplete
                    [placeholder]="'Escribe y selecciona entre las opciones'"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="lugarColegio"
                    [options]="filteredLugarColegio"
                    (filterChange)="getLugarColegio($event)"
                    (controlValue)="updateLugar($event)"
                    class="autocomplete"
                ></autocomplete>

                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['year_egreso']"
                    [(value)]="usuario['usuario_' + pais]['year_egreso']"
                    [key]="'year_egreso'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuario['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>

                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">Atualização</button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Retornar a página inicial
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    Nós atualizamos seus dados com sucesso. Vamos redirecioná-lo para a página principal em um instante.
</ng-container>
