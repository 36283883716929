import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "menu-landing",
    templateUrl: "./menu-landing.component.html",
    styleUrls: ["./menu-landing.component.scss"]
})
export class MenuLandingComponent implements OnInit {
    config: typeof config
    constructor(protected router: Router) {}

    ngOnInit(): void {
        this.config = config
    }

    goToRegister() {
        this.router.navigate(["/usuarios/registro"])
    }
}
