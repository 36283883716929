<div class="login-box">
    <ng-container *ngIf="!isLoggedIn()">
        <form novalidate [formGroup]="form" (ngSubmit)="login()">
            <div class="mensaje-error" *ngIf="invalidLogin">
                <div *ngIf="invalidLogin"><p [innerHTML]="invalidLoginMessage"></p></div>
            </div>
            <h2 class="login-title">{{ "landing.login.titulo" | t }}</h2>
            <div class="login-inputs">
                <div class="input-wrap">
                    <div class="form-group">
                        <input
                            type="text"
                            name="email"
                            [(ngModel)]="email"
                            [placeholder]="config.mensajesLogin.idPlaceholder"
                            class="form-control"
                            formControlName="email"
                        />
                        <div class="mensaje-error-input" *ngIf="checkEmail()">
                            <p>{{ "landing.login.error_user" | t }}</p>
                        </div>
                    </div>
                    <div class="form-group">
                        <input
                            type="password"
                            name="password"
                            [(ngModel)]="password"
                            placeholder="Senha"
                            *ngIf="!isLoggedIn()"
                            class="form-control"
                            formControlName="password"
                        />
                        <div class="mensaje-error-input" *ngIf="checkPassword()">
                            <p>{{ "landing.login.error_pswd" | t }}</p>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-lg btn-block button-email" *ngIf="!isLoggedIn()">
                    {{ "landing.login.btn_email" | t }}
                </button>
                <google-button [validarIdentificador]="true" class="btn-block"></google-button>
                <!-- <facebook-button [showIcono]="false" class="btn-block button-facebook"></facebook-button> -->
            </div>

            <div class="bottom-options">
                <div class="pwd-reset" *ngIf="enablePasswordRecovery">
                    <a [routerLink]="['/usuarios/passwords/forgot' || forgot_password_route]">
                        {{ "landing.login.btn_recover" | t }}
                    </a>
                </div>
                <div class="registro" *ngIf="enableRegister">
                    <cui-button-link type="button" class="btn_style" (click)="goToRegister()">
                        {{ "landing.login.btn_register" | t }}
                    </cui-button-link>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="isLoggedIn()">
        <div class="loggedIn-box">
            <div class="row">
                <div class="col-md-6">
                    <cui-button-link [isBlock]="true" class="btn_style btn-home" routerLink="/home">
                        <ogr-icon name="home-o" align="text-top" class="icono"></ogr-icon>
                        &nbsp;{{ "landing.login.btn_home" | t }}
                    </cui-button-link>
                </div>
                <div class="col-md-6">
                    <cui-button [isBlock]="true" buttonType="cancel" (click)="logout()" class="btn_style_2">
                        {{ "landing.login.btn_logout" | t }}
                    </cui-button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<request-reset-password></request-reset-password>
