<div data-spy="scroll" data-target=".navbar-puntaje" (window:resize)="resizeElements()">
    <div class="mascara">
        <!-- Login -->
        <div id="section1">
            <div class="caja caja-inicio">
                <div class="fading-image" [lazyLoad]="landingImage" [offset]="200"></div>
                <div class="caja-inner-margin">
                    <menu-landing></menu-landing>
                    <div class="titulo">
                        <img src="assets/img/logos/logo_principal.png" class="landing-logo" />
                        <h1>
                            <span [innerHTML]="'landing.inicio.seccion1.titulo' | t | trustedhtml"></span>
                        </h1>
                        <h2>
                            <span [innerHTML]="'landing.inicio.seccion1.subtitulo' | t | trustedhtml"></span>
                        </h2>
                    </div>
                    <div class="content-login">
                        <login-aprendo-livre
                            class="login"
                            [enablePasswordRecovery]="true"
                            [enableRegister]="true"
                        ></login-aprendo-livre>
                    </div>
                </div>
            </div>
        </div>
        <div class="caja sesion_asignaturas" id="section2">
            <div class="caja-inner-margin">
                <div class="titulo">
                    <h1>
                        <span [innerHTML]="'landing.inicio.seccion2.titulo' | t | trustedhtml"></span>
                    </h1>
                    <div class="line"></div>
                    <h2>{{ "landing.inicio.seccion2.subtitulo" | t }}</h2>
                </div>
                <div class="content-asignaturas">
                    <div class="asignatura" *ngFor="let asignatura of asignaturas">
                        <ogr-icon name="{{ asignatura.icono }}" class="icono {{ asignatura.nombre }}"></ogr-icon>
                        <div class="name">{{ asignatura.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="caja sesion_como_funciona" id="section3">
            <div class="caja-inner-margin">
                <div class="sesion-como-funciona-fondo">
                    <div class="content-video">
                        <div class="descripcion">
                            <h1>
                                <span [innerHTML]="'landing.inicio.seccion3.titulo' | t | trustedhtml"></span>
                            </h1>
                            <br />
                            <p [innerHTML]="'landing.inicio.seccion3.parrafo' | t | trustedhtml"></p>
                            <div class="content-button">
                                <button class="btn btn-lg" (click)="goToRegister()">
                                    {{ "landing.inicio.seccion3.btn" | t }}
                                </button>
                            </div>
                        </div>
                        <div class="video">
                            <!-- Imagen por ahora -->
                            <img
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/aprendolivre/landing/vector.png"
                                class="img-vector"
                            />
                            <div class="wrap-video">
                                <iframe
                                    class="video-yt"
                                    src="https://www.youtube.com/embed/HRfGyeeq2Ew?ab_channel=AprendoLivre"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sesion-como-funciona-sin-fondo">
                    <div class="titulo">
                        <h1>
                            <span [innerHTML]="'landing.inicio.seccion4.titulo' | t | trustedhtml"></span>
                        </h1>
                        <div class="line"></div>
                        <h2>{{ "landing.inicio.seccion4.subtitulo" | t }}</h2>
                    </div>
                    <br />
                    <div class="content-cuadrados">
                        <div class="content-cuadrado">
                            <div class="imagen"><img src="assets/img/material_multimidia.png" /></div>
                            <div class="titulo-cuadrados">
                                <h2>{{ "landing.inicio.seccion4.contenido1.titulo" | t }}</h2>
                            </div>
                            <div class="descripcion">
                                <p>{{ "landing.inicio.seccion4.contenido1.parrafo" | t }}</p>
                            </div>
                        </div>
                        <div class="content-cuadrado fondo-cuadrado">
                            <div class="imagen"><img src="assets/img/planos_adaptados.png" /></div>
                            <div class="titulo-cuadrados con-fondo">
                                <h2>{{ "landing.inicio.seccion4.contenido2.titulo" | t }}</h2>
                            </div>
                            <div class="descripcion con-fondo">
                                <p>{{ "landing.inicio.seccion4.contenido2.parrafo" | t }}</p>
                            </div>
                        </div>
                        <div class="content-cuadrado">
                            <div class="imagen"><img src="assets/img/feedback_imediato.png" /></div>
                            <div class="titulo-cuadrados">
                                <h2>{{ "landing.inicio.seccion4.contenido3.titulo" | t }}</h2>
                            </div>
                            <div class="descripcion">
                                <p>{{ "landing.inicio.seccion4.contenido3.parrafo" | t }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="caja sesion_aulas-en_vivo" id="section4">
            <div class="caja-inner-margin">
                <div class="content-descripcion">
                    <div class="titulo">
                        <h1>{{ "landing.inicio.seccion5.titulo" | t }}</h1>
                    </div>
                    <p>
                        {{ "landing.inicio.seccion5.parrafo" | t }}
                    </p>
                    <div class="content-button">
                        <a class="btn" href="https://www.youtube.com/channel/UCkTdvl4f8bM4cLfvjas7wbg/featured">
                            {{ "landing.inicio.seccion5.btn" | t }}
                            <img class="triangulito" src="assets/img/triangulito.svg" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Blog -->

        <div class="caja seccion_blog" id="section5">
            <div class="caja-inner-margin">
                <div class="content-descripcion">
                    <div class="titulo">
                        <h1>Blog</h1>
                    </div>
                    <div class="content-button inner-wrap">
                        <blog-landing></blog-landing>
                    </div>
                </div>
            </div>
        </div>

        <div class="imagen-footer">
            <h1>
                <span [innerHTML]="'landing.inicio.topfooter.titulo' | t | trustedhtml"></span>
            </h1>
            <a class="btn btn-lg" (click)="goToRegister()">{{ "landing.inicio.topfooter.btn" | t }}</a>
        </div>
    </div>
    <footer-component id="section5"></footer-component>
</div>
